
@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.03);
  }
}

.card {
  background: #00100E;
  position: relative;
}

.card.mobile {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 32px;
  min-height: calc(100vh - 64px);
}

.card:not(.mobile) {
  box-sizing: border-box;
  width: 500px;
  margin: 32px auto;
  border-radius: 12px;
  padding: 32px;
  left: -250px;
}

.card.center.center {
  left: 0;
}

.greenCard {
  position: relative;
  z-index: 4;
}

.greenCardContent {
  position: relative;
  z-index: 2;
  will-change: transform;
}

.greenCardAnimation {
  background: #12A93D;
  position: absolute;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  border-radius: 12px;
  z-index: 1;
  will-change: transform;
}

.active {
  animation-name: pulse;
  animation-duration: .7s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.help {
  box-sizing: border-box;
  background: #828B8B;
  position: absolute;
  top: -50px;
  right: -532px;
  width: 500px;
  border-radius: 0 12px 12px 0;
  padding: 48px 32px 48px 16px;
}

.blob {
  position: relative;
  z-index: 5;
}

.blob:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  background: #fff;
  bottom: -7px;
  left: 40px;
  transform: rotate(45deg);
  z-index: -1;
}

.blob.right:before {
  left: initial;
  right: calc(50% - 113px);
}

.blob.desktop {
  top: -162px !important;
  height: 146px !important;
}


@media screen and (max-width: 1070px) {
  .help {
    width: 420px;
    right: -452px;
  }
  .card:not(.mobile) {
    width: 420px;
    left: -210px;
  }
}

@media screen and (max-width: 373px) {
  .blob.right.blob.right {
    height: 172px !important;
    top: -180px !important;
    left: calc(50% - 128px) !important;
  }
}

@media screen and (max-width: 375px) {
  .blob.right:before {
    left: initial;
    right: calc(50% - 116px) !important;
  }
  .blob:not(.right) {
    top: -214px !important;
    height: 192px !important;
    padding: 12px !important;
  }
}

@media screen and (max-width: 360px) {
  .blob.right:before {
    left: initial;
    right: calc(50% - 104px) !important;
  }
}

@media screen and (max-width: 320px) {
  .blob.right:before {
    left: initial;
    right: calc(50% - 114px) !important;
  }
}

