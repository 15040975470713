.sberPayBtn.sberPayBtn  {
    font: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    border-radius: 8px;
    outline: none;
    transform: translateZ(0);

    position: relative;
    color: #fff;
    background-image: linear-gradient(92.52deg, #F2EA00 -5.72%, #03D31D 33.01%, #0FB6FE 104.94%);
    transition: all .5s ease;

    justify-content: flex-start;
    height: 72px;
    padding: 0 24px;
}

.sberPayBtn.sberPayBtn:disabled {
    opacity: .7;
    background-image: linear-gradient(#ccc 0%, #ccc 100%);
}

/*.sberPayBtn:hover {*/
/*    color: #fff;*/
/*    background-position: -120px 0;*/
/*    cursor: pointer;*/
/*}*/

/*.sberPayBtn:before {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: 8px;*/
/*    content: "";*/
/*    top: 5px;*/
/*    background-image: linear-gradient(241.57deg, #00aded -8.4%, #42e3b4 29.3%, #00d900 60.42%, #faed00 85.41%);*/
/*    background-position: -180px 0;*/
/*    background-size: 200% auto;*/
/*    opacity: .55;*/
/*    -webkit-filter: blur(16px);*/
/*    filter: blur(16px);*/
/*    transition: all .3s ease;*/
/*}*/

/*.sberPayBtn:after {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: 8px;*/
/*    content: "";*/
/*    background-color: rgba(0, 0, 0, .1);*/
/*    opacity: 0;*/
/*    transition: opacity .4s ease;*/
/*}*/
