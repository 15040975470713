.container {
  display: flex;
}

.copyBtn.copyBtn {
  background: #56C6FF;
  border-radius: 6px;
  padding: 0;
  flex-shrink: 0;
  &:hover {
    background: #3c8bb3;
  }
  &:active {
    background: #a2e0ff;
  }
}

.copyBtnIcon {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center center;
}

.amount {
  font-family: 'MontserratBold', sans-serif;
  margin-left: 8px;
  margin-right: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #EAF8FF;
}
