.will {
    transform: translateZ(0);
    position: absolute;
    min-height: 100vh;
    min-height: 100svh;
    min-width: 100vw;
    transition: transform ease .4s, filter .4s;
    will-change: transform, filter;
    display: flex;
    flex-direction: column;
}

.my-node-enter {
    transform: translateX(100%);
}
.my-node-enter-active {
    transform: translateX(0);
}
.my-node-enter-done {
    transform: translateX(0);
}
.my-node-exit {
    transform: translateX(0);
    filter: brightness(1);
}
.my-node-exit-active {
    transform: translateX(-50%);
    filter: brightness(.6);
}
.my-node-exit-done {
    transform: translateX(100%);
    filter: brightness(.6);
    display: none;
}

.will.prev {
    max-height: 100vh;
    max-height: 100svh;
    overflow: hidden;
}
